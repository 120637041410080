import defaultTheme from "@lib/simba/theme"
import merge from "lodash.merge"

const overrides = {
  backgroundLight: "#ffffff",
  colorText: "#000000",
  colorLink: "#000000",
  colorLinkHover: "rgba(#6D6700, 0.7)",
  colorTextTitle: "#E11031",
  grey: "#f8f8f8",
  colors: {
    secondary: "#E11031",
  },
  header: {
    colorText: "#ffffff",
    background: "#797419",
  },
  menu: {
    background: "#E10024",
    colorLink: "#6D6700",
    colorLinkHover: "rgba(86, 120, 138, 0.7)",
    colorHamburger: "#FFF",
  },
  triptyque: {
    background: "#FCFBFA",
  },
  citation: {
    background: "#EFEFEF",
  },
  footer: {
    background: "#fff",
  },
}

export default merge(defaultTheme.theme, overrides)
